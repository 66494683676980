import React, { FC } from 'react'
import { ButtonGroup } from '@mui/material'
import { Button } from 'gatsby-material-ui-components'
import { Add, Delete } from '@mui/icons-material'
import GenericTablePage from '@templates/Generic/Table'
import useApi, { wasSuccess } from '@hooks/useApi'
import { navigate } from 'gatsby'
import { deleteJumpHosts, getJumpHosts } from '@utils/api/jumphosts'
import { digitRenderer } from '@utils/tables'

const columns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 180,
    hide: true,
    type: 'enhancedString'
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 180,
    type: 'enhancedString'
  },
  {
    field: 'credentials_id',
    headerName: 'Credential',
    width: 180,
    type: 'enhancedString'
  },
  {
    field: 'hostname',
    headerName: 'Hostname',
    width: 180,
    type: 'enhancedString'
  },
  {
    field: 'ssh_port',
    headerName: 'SSH Port',
    width: 180,
    type: 'number',
    renderCell: digitRenderer
  }
]

const HostJumpHostsPage = () => {
  const api = useApi({ apiMethod: getJumpHosts })

  const deleteApi = useApi({
    apiMethod: deleteJumpHosts,
    requestOnMount: false
  })

  return (
    <GenericTablePage
      maxWidth="md"
      keyProp="id"
      api={api}
      title="Jump Hosts"
      feature="jump_hosts"
      breadcrumbs={[
        {
          title: 'Hosts',
          to: '/hosts/'
        },
        {
          title: 'Jump Hosts'
        }
      ]}
      gridOptions={{
        columns,
        onRowClick: (param: { id: string }) => {
          navigate(`/hosts/jump-hosts/edit/${param.id}/`)
        }
      }}
      actions={
        <ButtonGroup
          color="primary"
          variant="outlined"
          aria-label="Host Actions"
        >
          <Button endIcon={<Add />} to="/hosts/jump-hosts/add/">
            Add Jump Host
          </Button>
        </ButtonGroup>
      }
      bulkActions={[
        {
          text: 'Delete Selected',
          icon: <Delete fontSize="small" />,
          action: async (selection: string[]) => {
            await deleteApi.makeRequest(selection).then((response) => {
              if (wasSuccess(response)) {
                deleteApi.enqueueSnackbar(
                  'Jump host(s) successfully deleted.',
                  {
                    variant: 'success'
                  }
                )
              }
            })
          },
          autoRefresh: true
        }
      ]}
    />
  )
}

export default HostJumpHostsPage
